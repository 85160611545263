
import { Options, Vue } from "vue-class-component";
import {
  initialName,
  dateToDateString,
  dateToTime
} from "@/app/infrastructures/misc/Utils";
import { NotificationController } from "@/app/ui/controllers/NotificationController";
import ModalShowImage from "@/app/ui/components/modal-show-image/index.vue";
import "quill/dist/quill.core.css";

@Options({
  components: {
    ModalShowImage
  }
})
export default class Detail extends Vue {
  isShow = false;

  get id() {
    return this.$route.params.id;
  }

  get isLoading() {
    return NotificationController.isLoading;
  }

  created() {
    this.getDetail(this.id);
  }

  getDetail(id: any) {
    NotificationController._getDetail(id);
  }

  get detailData() {
    return NotificationController.detailNotification;
  }

  get isError() {
    return NotificationController.isError;
  }

  get errorCause() {
    return NotificationController.errorCause;
  }

  onOpenShowImage() {
    this.isShow = true;
  }

  onCloseShowImage() {
    this.isShow = false;
  }

  get initialNameNotification() {
    return initialName(`${this.detailData.createdBy}`);
  }

  formatDate(date: string) {
    return `${dateToDateString(new Date(date))}, ${dateToTime(
      new Date(date)
    )} WIB`;
  }

  fileName(name: string) {
    const shortName = `${name.substr(0, 28)}...`;
    return name.length > 10 ? shortName : name;
  }

  goBack() {
    this.$router.push(`/notifications`);
  }
}
